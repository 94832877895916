// contentPT.js

const contentPT = {
  link: "https://clube-de-voos.formaloo.me/aeroportos-pt",
  labels: {
    homeAirport: "Escolhe o teu aeroporto de origem",
  },
  popup: {
    heading: "Subscreve ao",
    text: "Foi enviado um email de verificação. Por favor, verifica a tua caixa de correio electrónico e clica no link para ativar a subscrição.",
  },
  alert: "A submeter...",
  faqData: [
    {
          question: "Como funciona o Clube de Voos?",
          answer: "O Clube de Voos envia regularmente ofertas exclusivas e promoções personalizadas diretamente para a tua caixa de correio eletrónico. A nossa equipa pesquisa as melhores tarifas aéreas, incluindo erros de tarifas e descontos de última hora, para que possas economizar nas tuas viagens.",
    },
    {      
          question: "Qual é a diferença entre a subscrição gratuita e a Premium?",      
          answer: "Com a subscrição gratuita, recebes pelo menos 2 ofertas por mês para o teu aeroporto de partida. A subscrição Premium oferece não só mais ofertas como também promoções personalizadas incluindo ofertas relacionadas com programas de fidelidade para certas companhias aéreas e acesso exclusivo a tarifas de erro raras.",
    },
    {
          question: "Porque devo usar o Clube de Voos se já sei pesquisar voos e tenho todas as ferramentas que vocês utilizam?",
          answer: `<p><strong>Automação e APIs</strong></p>
    <p>Graças à automação e às integrações via API, todo o trabalho pesado de pesquisa é feito por nós. Os nossos bots pesquisam tarifas aéreas todos os dias a toda a hora e tu recebes os resultados diretamente na tua caixa de email.</p>
    
    <p><strong>Timing</strong></p>
    <p>Mesmo que sejas bom a pesquisar, o resultado depende sempre do momento em que fazes a pesquisa. O que encontras hoje vai ser diferente do que encontras amanhã ou na próxima semana. Como armazenamos dados de preços passados, sabemos reconhecer quando um determinado valor é realmente uma boa oportunidade.</p>
    
    <p><strong>Amplitude/Alcance</strong></p>
    <p>Podes estar de olho num destino específico, mas isso não te impede de descobrir grandes oportunidades noutros locais em que nem tinhas pensado. O Clube de Voos abre-te possibilidades para destinos que possas nem ter considerado.</p>
    
    <p><strong>Ofertas Personalizadas (Premium)</strong></p>
    <p>Com base nas tuas preferências (datas, destinos, companhias aéreas, etc.), enviamos-te alertas à medida. Assim, não perdes tempo a filtrar resultados que não interessam.</p>
    
    <p><strong>Menos Trabalho e Mais Vantagens</strong></p>
    <p>Enquanto nós fazemos as pesquisas e guardamos o histórico, tu podes aproveitar esse tempo para planear o que mais gostas na viagem. Nós cuidamos da caça às pechinchas por ti!</p>
    
    <p><strong>Erros de Tarifa</strong></p>
    <p>Estas tarifas são tão raras que é praticamente impossível encontrares uma por conta própria, a não ser que passes o dia inteiro a pesquisar voos para vários destinos. E mesmo assim, a probabilidade de tropeçares num erro de tarifa dessa forma é quase a mesma que ganhar a lotaria!</p>`,
    },
    {
      question: "Como posso tirar o melhor proveito do Clube de Voos?",
      answer: `<p>Há várias formas de aproveitar ao máximo a tua subscrição do Clube De Voos. Uma delas é escolher de antemão para onde queres ir e ficar atento às oportunidades que te enviamos por e-mail. Se receberes uma oportunidade de voo para o destino que queres, é só avançar e garantir a tua reserva. Para os subscritores Premium, enviamos no e-mail as datas específicas com os melhores preços para que não precises de perder tempo a procurar por todas as datas possíveis. Se não receberes uma oportunidade até 3 meses antes das datas que planeaste, provavelmente é melhor fazeres a reserva do melhor voo que encontrares nessa altura.</p>

      <p>Outra maneira é manter as opções em aberto e aproveitar a melhor oportunidade que te enviarmos (a melhor para ti!), desde que faças a reserva pelo menos 3 meses antes das datas planeadas.</p>

      <p>Uma terceira maneira é aproveitar qualquer 'Erro de Tarifa' ou ofertas ‘Flash Sales’. As 'Flash Sales' normalmente só são aplicáveis se puderes ir para um destino inesperado com pouca antecedência. Vais porque preço é irresistível e, como dizem por aí, a vida é curta e só se vive uma vez: estás pronto para receber um e-mail na quarta-feira e embarcar na sexta-feira?</p>

      <p>Para os subscritores Premium, podes escolher até 3 destinos favoritos e nós fazemos o trabalho pesado por ti. Os 3 destinos escolhidos provavelmente serão bloqueados durante um período de 12 meses.</p>

      <p>Tens outras ideias de como o Clube De Voos pode ser uma ferramenta fantástica para TI? Diz-nos (apoio@clubedevoos.com), porque a nossa missão é ser o MELHOR serviço mundial de alertas para a comunidade de língua portuguesa!</p>`,
    },
    {      
          question: "Os preços das tarifas de erro são garantidos?",
          answer: "As tarifas de erro são raras e resultam de erros de preço nas companhias aéreas. Embora muitas vezes as companhias aéreas honrem essas tarifas, não podemos garantir que o farão sempre. É sempre uma boa ideia esperar pela confirmação da reserva antes de fazeres outros planos de viagem. As tarifas de erro também de ser marcadas de imediato porque normalmente desaparecem em poucas horas.",
    },
    {      
          question: "Como posso personalizar as minhas preferências de viagem?",
          answer: "Os membros Premium podem personalizar as suas preferências de viagem através da sua conta no nosso site. Neste momento, podem atualizar o seu aeroporto de partida e definir a classe dos voos e a companhia aérea com a qual recebem pontos/milhas. Em breve, vamos adicionar: Destinos preferidos; Datas de viagem preferidas; Interesses (Desporto; Cultural; Relaxamento; etc...) e outros critérios para personalizarmos as ofertas de forma a que se adequem efetivamente às tuas preferências.",
    },      
    {
          question: "Com que frequência enviam emails?",
          answer: "Enviamos e-mails regularmente com as melhores ofertas que encontramos. A frequência dos e-mails pode variar, mas normalmente podes esperar receber entre 2 a 4 e-mails por mês para subscritores gratuitos e 1 a 3 por semana para subscritores Premium.",
    },
    {     
          question: "As ofertas são realmente tão boas quanto dizem?",
          answer: "Sim, a nossa equipa dedica-se a encontrar as melhores ofertas possíveis. No entanto, os erros de tarifa (Premium) desaparecem rapidamente, por isso recomendamos que aproveitem essas ofertas assim que as recebem.",
    },
    {      
          question: "A minha informação pessoal está segura?",
          answer: "Sim, levamos a tua privacidade muito a sério. Só recolhemos o teu nome e e-mail. O processamento de pagamentos é feito diretamente no ambiente seguro da Stripe, e não temos acesso a qualquer informação financeira fornecida durante esse processo. Protegemos as tuas informações pessoais e não partilhamos os teus dados com terceiros sem o teu consentimento.",
    },
    {      
          question: "O que acontece se eu encontrar um problema ou tiver uma dúvida?",
          answer: "Estamos aqui para ajudar! Se tiveres alguma dúvida ou encontrares algum problema, podes entrar em contacto connosco através do nosso email de suporte apoio@clubedevoos.com.",
    },
    {      
          question: "Posso mudar do plano gratuito para o Premium mais tarde?",
          answer: "Sim, podes mudar para a subscrição Premium a qualquer momento. Basta fazer o upgrade através do nosso site e começar a aproveitar os benefícios exclusivos imediatamente. Também podes mudar de Premium para gratuito a qualquer momento.",
    },
    {      
          question: "Se eu cancelar o plano Premium, perco todos os meus benefícios imediatamente?",
          answer: "Não, os benefícios Premium permanecem até ao final do período subscrito e os utilizadores podem também optar por manter a versão gratuita quando cancelam o Premium.",
    },
    {      
          question: "Vocês oferecem pacotes de férias?",
          answer: "De momento, o Clube de Voos foca-se somente em encontrar e enviar as melhores ofertas de voos. No entanto, estamos sempre a explorar novas formas de oferecer mais valor aos nossos membros, incluindo a possibilidade de pacotes de férias no futuro.",
    },    
    {  
          question: "Posso reservar os voos através do vosso site?",
          answer: "As ofertas que enviamos incluem links diretos para os sites das companhias aéreas, Skyscanner, Google Flights Search, ou agências de viagens onde podes reservar os voos. De momento não podes fazer as reservas diretamente no nosso site, mas é algo que podemos vir a implementar num futuro próximo.",
    },
    {      
          question: "Como posso aproveitar as ofertas que vocês enviam?",
          answer: "Para aproveitar as ofertas, basta clicar no link fornecido no email da oferta. Isso envia-te para o site onde podes fazer a reserva. Recomendamos que reserves o mais rápido possível, pois as tarifas aéreas podem mudar rapidamente especialmente para voos com datas mais próximas e definitivamente para erros de tarifa.",
    },
    {      
          question: "Podem encontrar voos específicos e/ou pacotes para mim?",
          answer: "O Clube de Voos foca-se em enviar as melhores ofertas e promoções que encontramos. Para os nossos membros Premium, oferecemos promoções personalizadas com base nas suas preferências de viagem, garantindo que recebam ofertas que melhor se adequem às suas necessidades. No entanto, não oferecemos um serviço de busca de voos ou pacotes de férias específicos.",
    },
    {      
          question: "Posso cancelar a minha subscrição a qualquer momento?",
          answer: "Sim, podes cancelar a subscrição a qualquer momento. Basta clicar no link de cancelamento no final de qualquer email que enviamos ou aceder à tua conta no nosso site e cancelar a subscrição (Premium).",
    },
  ],
};

export default contentPT;
